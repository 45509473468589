<template>
	<div>
		<CRow>
			<CCol class="text-right mb-3">
				<CDropdown
					:disabled="isExporting"
					class="mr-3 d-inline-block"
					color="tertiary"
					data-test-id="btn-pre-order-pickup-at-store-report"
					placement="bottom-end"
					add-menu-classes="export-dropdown-menu"
					@click="exportPreOrderPickupAtStoreReport"
				>
					<CDropdownItem @click="exportPreOrderPickupAtStoreReport">
						Pre-order pickup at store report
					</CDropdownItem>
					<template #toggler-content>
						{{ isExporting ? "Exporting" : "Export" }}
					</template>
				</CDropdown>
				<router-link
					:to="{ name: 'PreOrderCreate' }"
					class="btn btn-secondary"
				>
					<CIcon class="mr-2" name="cil-plus" />
					Create pre-order campaign
				</router-link>
			</CCol>
		</CRow>
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						data-test-id="search-input"
						placeholder="Search by pre-order name or ID"
					>
						<template #prepend-content>
							<CIcon
								name="cil-magnifying-glass"
								data-test-id="search-button"
							/>
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					class="ml-3"
					data-test-id="filter-button"
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="filter-box rounded-sm pt-3 px-3 mb-3"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									v-model="dropdown.preOrderStatus"
									:searchable="false"
									:options="PREORDER_STATUS_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Campaign status"
									@input="handlePreOrderStatus"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="dropdown.preOrderPayment"
									:searchable="false"
									:options="PREORDER_PAYMENT_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Payment options"
									@input="handlePreOrderPaymentStatus"
								/>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="datePreOrderStartRange"
									label="Pre-order start date"
									placeholder="All date"
									mode="range"
									@input="handleFilterPreOderStartDate"
								>
									<template #append-content>
										<CIcon
											class="cil-calendar"
											name="cil-calendar"
										/>
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="datePreOderEndRange"
									label="Pre-order end date"
									placeholder="All date"
									mode="range"
									@input="handleFilterPreOderEndDate"
								>
									<template #append-content>
										<CIcon
											class="cil-calendar"
											name="cil-calendar"
										/>
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="dropdown.periodStatus"
									:searchable="false"
									:options="PREORDER_PERIODS_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Pre-order period"
									@input="handlePeriodStatus"
								/>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="datePaymentStartRange"
									label="Remaining payment start date"
									placeholder="All date"
									mode="range"
									@input="handleFilterPaymentStartDate"
								>
									<template #append-content>
										<CIcon
											class="cil-calendar"
											name="cil-calendar"
										/>
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="datePaymentEndRange"
									label="Remaining payment end date"
									placeholder="All date"
									mode="range"
									@input="handleFilterPaymentEndDate"
								>
									<template #append-content>
										<CIcon
											class="cil-calendar"
											name="cil-calendar"
										/>
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="dropdown.paymentPeriodStatus"
									:searchable="false"
									:options="PREORDER_PERIODS_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Remaining payment period"
									@input="handlePaymentPeriodStatus"
								/>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="dateReleaseDateRange"
									label="Product release date"
									placeholder="All date"
									mode="range"
									@input="handleFilterReleasedDate"
								>
									<template #append-content>
										<CIcon
											class="cil-calendar"
											name="cil-calendar"
										/>
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="dropdown.releaseStatus"
									:searchable="false"
									:options="PREORDER_RELEASED_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Product release status"
									@input="handleReleaseStatus"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>

		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="campaign(s) found"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: list.meta.lastPage,
						activePage: list.meta.currentPage,
					}"
					:fields="PREORDER_TABLE_FIELDS"
					:items="list.data"
					clickable-rows
					striped
					link-to="PreOrderEdit"
					vertical-align="top"
					class="table-custom"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{
									$t('global.searchNotFound', {
										field: 'pro-orders',
									})
								}}
							</p>
						</div>
					</template>
					<template #payment="{item}">
						{{ PREORDER_TITLE.PAYMENT[item] }}
					</template>
					<template #status="{item}">
						<CBadge class="badge-status" :color="PREORDER_COLOR.STATUS[item]">
							{{ PREORDER_TITLE.STATUS[item] }}
						</CBadge>
					</template>
					<template #periodStatus="{item}">
						<CBadge class="badge-status" :color="PREORDER_COLOR.PERIODS_STATUSES[item]">
							{{ PREORDER_TITLE.PERIODS_STATUSES[item] }}
						</CBadge>
					</template>
					<template #remainingStatus="{item}">
						<CBadge class="badge-status" :color="PREORDER_COLOR.PERIODS_STATUSES[item]">
							{{ PREORDER_TITLE.PERIODS_STATUSES[item] }}
						</CBadge>
					</template>
					<template #releaseStatus="{item}">
						<CBadge class="badge-status" :color="PREORDER_COLOR.PERIODS_STATUSES[item]">
							{{ PREORDER_TITLE.PERIODS_STATUSES[item] || '-' }}
						</CBadge>
					</template>
					<template #duplicateId="{item}">
						<CLink
							variant="ghost"
							color="secondary"
							:to="{ name: 'PreOrderCreate', query: { ref: item } }"
						>
							Duplicate
						</CLink>
					</template>
				</BaseTable>
				<BaseModalConfirm
					ref="modal-export-pre-order-pickup-at-store-report"
					:is-submitting="isExporting"
					:close-after-confirm="false"
					title="Export Pre-order pickup at store report"
					description="The report will be sent to your email after combinded all the files. It might be taking for 5 - 30 mins, please be patient."
					primary-button-text="Send"
					primary-button-loading-text="Sending"
					@onConfirm="handleExportPreOrderPickupAtStoreReport"
				/>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseInputDate from '@/components/BaseInputDate.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import {
	PREORDER_TABLE_FIELDS,
	PREORDER_STATUS_OPTIONS,
	PREORDER_PAYMENT_OPTIONS,
	PREORDER_PERIODS_OPTIONS,
	PREORDER_RELEASED_OPTIONS,
	PREORDER_TITLE,
	PREORDER_COLOR,
} from '../enums/preorders';
import {
	cleanObject,
	randomSearchString,
	hasSearchFilter,
	getBooleanQueryParameter,
	cloneDeep,
	convertDateRangeToUTC,
} from '../assets/js/helpers';
import {
	exportPreOrderPickupAtStoreReportAPI,
} from '../services/api/export.api';

export default {
	name: 'PreOrders',
	components: {
		BaseInputDate,
		BaseModalConfirm,
	},
	data() {
		return {
			PREORDER_TABLE_FIELDS,
			PREORDER_STATUS_OPTIONS,
			PREORDER_PAYMENT_OPTIONS,
			PREORDER_PERIODS_OPTIONS,
			PREORDER_RELEASED_OPTIONS,
			PREORDER_TITLE,
			PREORDER_COLOR,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				is_active: getBooleanQueryParameter(this.$route.query.is_active),
				payment_option_type: this.$route.query.payment_option_type || null,
				period_start_at_from: this.$route.query.period_start_at_from || null,
				period_start_at_to: this.$route.query.period_start_at_to || null,
				period_end_at_from: this.$route.query.period_end_at_from || null,
				period_end_at_to: this.$route.query.period_end_at_to || null,
				remaining_start_at_from: this.$route.query.remaining_start_at_from || null,
				remaining_start_at_to: this.$route.query.remaining_start_at_to || null,
				remaining_end_at_from: this.$route.query.remaining_end_at_from || null,
				remaining_end_at_to: this.$route.query.remaining_end_at_to || null,
				released_period_from: this.$route.query.released_period_from || null,
				released_period_to: this.$route.query.released_period_to || null,
				period_status: this.$route.query.period_status || null,
				remaining_status: this.$route.query.remaining_status || null,
				released_status: this.$route.query.released_status || null,
				r: randomSearchString(),
			},
			dropdown: {
				preOrderStatus: this.getSelectedValue(this.$route.query.is_active, PREORDER_STATUS_OPTIONS),
				preOrderPayment: this.getSelectedValue(this.$route.query.payment_option_type, PREORDER_PAYMENT_OPTIONS),
				periodStatus: this.getSelectedValue(this.$route.query.period_status, PREORDER_PERIODS_OPTIONS),
				paymentPeriodStatus: this.getSelectedValue(this.$route.query.remaining_status, PREORDER_PERIODS_OPTIONS),
				releaseStatus: this.getSelectedValue(this.$route.query.released_status, PREORDER_RELEASED_OPTIONS),
			},
			isShowFilter: false,
			isExporting: false,
		};
	},
	computed: {
		...mapState('preorders', {
			list: 'list',
		}),
		isLoading() {
			return this.list.isLoading;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		datePreOrderStartRange() {
			if (!this.queryParams.period_start_at_from || !this.queryParams.period_start_at_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.period_start_at_from),
				end: new Date(this.queryParams.period_start_at_to),
			};
		},
		datePreOderEndRange() {
			if (!this.queryParams.period_end_at_from || !this.queryParams.period_end_at_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.period_end_at_from),
				end: new Date(this.queryParams.period_end_at_to),
			};
		},
		datePaymentStartRange() {
			if (!this.queryParams.remaining_start_at_from || !this.queryParams.remaining_start_at_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.remaining_start_at_from),
				end: new Date(this.queryParams.remaining_start_at_to),
			};
		},
		datePaymentEndRange() {
			if (!this.queryParams.remaining_end_at_from || !this.queryParams.remaining_end_at_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.remaining_end_at_from),
				end: new Date(this.queryParams.remaining_end_at_to),
			};
		},
		dateReleaseDateRange() {
			if (!this.queryParams.released_period_from || !this.queryParams.released_period_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.released_period_from),
				end: new Date(this.queryParams.released_period_to),
			};
		},
	},
	async created() {
		this.isShowFilter = this.hasFilter;
		await this.getPreOrders(this.queryParams);
	},
	methods: {
		...mapActions({
			getPreOrders: 'preorders/getPreOrders',
			showToast: 'toast/showToast',
		}),

		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},

		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},

		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},

		handlePreOrderStatus({ value }) {
			this.queryParams.page = null;
			this.queryParams.is_active = value ?? null;
			this.updateUrlParams();
		},

		handlePreOrderPaymentStatus(event) {
			this.queryParams.page = null;
			this.queryParams.payment_option_type = event.value;
			this.updateUrlParams();
		},

		handlePeriodStatus(event) {
			this.queryParams.page = null;
			this.queryParams.period_status = event.value;
			this.updateUrlParams();
		},

		handlePaymentPeriodStatus(event) {
			this.queryParams.page = null;
			this.queryParams.remaining_status = event.value;
			this.updateUrlParams();
		},

		handleReleaseStatus(event) {
			this.queryParams.page = null;
			this.queryParams.released_status = event.value;
			this.updateUrlParams();
		},

		handleFilterPreOderStartDate(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.period_start_at_from = start;
			this.queryParams.period_start_at_to = end;
			this.updateUrlParams();
		},

		handleFilterPreOderEndDate(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.period_end_at_from = start;
			this.queryParams.period_end_at_to = end;
			this.updateUrlParams();
		},

		handleFilterPaymentStartDate(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.remaining_start_at_from = start;
			this.queryParams.remaining_start_at_to = end;
			this.updateUrlParams();
		},

		handleFilterPaymentEndDate(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.remaining_end_at_from = start;
			this.queryParams.remaining_end_at_to = end;
			this.updateUrlParams();
		},

		handleFilterReleasedDate(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.released_period_from = start;
			this.queryParams.released_period_to = end;
			this.updateUrlParams();
		},

		getSelectedValue(selectedValue = null, options = []) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return (
				statusOptionValues.find(findStatus) || {
					name: null,
					value: null,
				}
			);
		},

		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},

		resetFilter() {
			this.queryParams = {
				q: null,
				per_page: null,
				is_active: null,
				payment_option_type: null,
				period_start_at_from: null,
				period_start_at_to: null,
				period_end_at_from: null,
				period_end_at_to: null,
				remaining_start_at_from: null,
				remaining_start_at_to: null,
				remaining_end_at_from: null,
				remaining_end_at_to: null,
				released_period_from: null,
				released_period_to: null,
				period_status: null,
				remaining_status: null,
				released_status: null,
				r: null,
			};

			this.updateUrlParams();
		},

		exportPreOrderPickupAtStoreReport() {
			this.$refs['modal-export-pre-order-pickup-at-store-report'].open();
		},

		async handleExportPreOrderPickupAtStoreReport() {
			this.isExporting = true;

			try {
				await exportPreOrderPickupAtStoreReportAPI({
					q: this.queryParams.q,
					is_active: this.queryParams.is_active,
					payment_option_type: this.queryParams.payment_option_type,
					period_start_at_from: this.queryParams.period_start_at_from,
					period_start_at_to: this.queryParams.period_start_at_to,
					period_end_at_from: this.queryParams.period_end_at_from,
					period_end_at_to: this.queryParams.period_end_at_to,
					remaining_start_at_from: this.queryParams.remaining_start_at_from,
					remaining_start_at_to: this.queryParams.remaining_start_at_to,
					remaining_end_at_from: this.queryParams.remaining_end_at_from,
					remaining_end_at_to: this.queryParams.remaining_end_at_to,
					released_period_from: this.queryParams.released_period_from,
					released_period_to: this.queryParams.released_period_to,
					period_status: this.queryParams.period_status,
					remaining_status: this.queryParams.remaining_status,
					released_status: this.queryParams.released_status,
				});

				this.showToast({
					header: this.$t('global.successMessageTitle'),
					content: `Your request has been completed.`,
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					header: this.$t('global.errorMessageExport'),
					content: this.$t('global.errorMessage'),
					type: 'danger',
				});
			} finally {
				this.isExporting = false;
				this.$refs['modal-export-pre-order-pickup-at-store-report'].close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	// Set placeholder of filter by date range
	::v-deep .form-control-date {
		input[type="input"] {
			@include placeholder-black;
		}
	}

	.badge-status {
		min-width: rem(65);
	}

	.filter-box {
		background-color: $color-gray-100;
	}
</style>
